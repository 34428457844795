import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import DiagramService from "../../../services/diagrams/DiagramService";
import { DiagramRepetitionsSetsVolumeForEachTrainingAndDate } from "../../../types/types";

type Props = {
    workbookId: number;
};

const DiagramRepetitionsSetsVolume: React.FC<Props> = ({ workbookId }) => {
    const [data, setData] = useState<any[][]>([]);
    const [loading, setLoading] = useState(true);
    const [maxWeek, setMaxWeek] = useState<number>(0); // dodaj na górze obok innych useState
    const [maxSets, setMaxSets] = useState<number>(0)


    useEffect(() => {
        DiagramService.getVolumeSetsRepsForEachDateInCategory(workbookId)
            .then((response) => {
                const rawData: DiagramRepetitionsSetsVolumeForEachTrainingAndDate[] = response.data;

                function getWeekNumber(dateStr: string): number {
                    const date = new Date(dateStr);
                    const start = new Date(date.getFullYear(), 0, 1);
                    const diff = (date.getTime() - start.getTime()) / 86400000;
                    return Math.ceil((diff + start.getDay() + 1) / 7);
                }


                const chartData: any[][] = [
                    ["Category", "Week", "Sets", "Volume", "Repetitions"],
                ];

                let localMaxWeek = 0;
                let localMaxSet = 0;

                rawData.forEach((item) => {
                    const week = getWeekNumber(item.trainingWeek);
                    localMaxWeek = Math.max(localMaxWeek, week);
                    localMaxSet = Math.max(localMaxSet, item.sets);
                    chartData.push([
                        item.category,
                        getWeekNumber(item.trainingWeek), // <-- Date obiekt, Google sam użyje timestampu
                        item.sets,
                        item.volume,
                        item.repetitions,
                        // item.trainingWeek,
                    ]);
                });

                setMaxWeek(localMaxWeek); // ustawiamy globalny stan
                setMaxSets(localMaxSet);
                setData(chartData);
            })
            .catch((error) => console.error("Error fetching diagram data:", error))
            .finally(() => setLoading(false));
    }, [workbookId]);

    const options = {
        title: "Volume / Sets / Repetitions by Category & Week",
        hAxis: {
            title: "Week number",
            viewWindow: {
              max: maxWeek + 2, // <-- dodajemy +2 jednostki
            },
          },
        vAxis: { 
            title: "Category",
            viewWindow: {
                max: maxSets + 2, // <-- dodajemy +2 jednostki
              },
         },
        bubble: { textStyle: { fontSize: 11 } },
        explorer: { actions: ["dragToZoom", "rightClickToReset"], axis: "horizontal" },
        tooltip: { isHtml: true },
        backgroundColor: 'white'
    };

    if (loading) return <span className="loader" />;

    return (
        // <div style={{ overflowX: "auto" }}>
            <Chart
                chartType="BubbleChart"
                // width="100%" 
                // height="350px"
                data={data}
                options={options}
            />
        // </div>
    );
};

export default DiagramRepetitionsSetsVolume;
