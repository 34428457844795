import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { NewInvitation } from '../../../types/types';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Service from '../../../services/invitations/';
import Modal from '../../../constants/Modal';

const SendInvitation: React.FC<{ refreshInvitations: () => void }> = ({ refreshInvitations }) => {
    const { t } = useTranslation('global');
    const [invitationSent, setInvitationSent] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const postInvitation = (values: { email: string }) => {
        const newInvitation: NewInvitation = {
            email: values.email
        };
        Service.postNewInvitation(newInvitation)
            .then(() => {
                setInvitationSent(true);
                refreshInvitations();
            })
            .catch(error => {
                console.error('Błąd podczas wysyłania zaproszenia:', error);
            });
    };

    const openModal = () => {
        setShowModal(true);
        setInvitationSent(false);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <div>
            <button onClick={openModal}>{t("buttons.send_invitation")}</button>

            <Modal show={showModal} onClose={closeModal} title={t("invitations.send_invitation")}>
                <Formik
                    initialValues={{ email: '' }}
                    validationSchema={Yup.object({
                        email: Yup.string()
                            .email(t("validation.invalid_email"))
                            .required(t("validation.this_field_is_required"))
                    })}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        postInvitation(values);
                        setSubmitting(false);
                        resetForm();
                    }}
                >
                    {formik => (
                        <Form>
                            <Field
                                name="email"
                                type="email"
                                placeholder={t("buttons.placeholder_send_invitation")}
                                className="form-field"
                            />
                            <ErrorMessage name="email" component="div" />
                            <button type="submit" disabled={formik.isSubmitting}>
                                {t("buttons.send_invitation")}
                            </button>
                            {invitationSent && <div>{t('clients.invitation_sent')}</div>}
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    );
};

export default SendInvitation;
