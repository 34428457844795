import React, { useMemo } from "react";
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { BodyParamsItem } from '../../types/types';

type Props = {
  allBodyParams: BodyParamsItem[] | null;
};

const ShowDiagram: React.FC<Props> = ({ allBodyParams }) => {
  if (!allBodyParams || allBodyParams.length === 0) {
    return <p>Brak danych do wyświetlenia wykresu.</p>;
  }

  // Tworzenie unikalnych dat
  const dateSet = new Set(allBodyParams.map(item => item.insert_date.split("T")[0]));
  const paramNames = Array.from(new Set(allBodyParams.map(item => item.dict_body_params_name)));

  // Budowanie struktury z pełnymi datami i wartościami
  const processedData = Array.from(dateSet).sort().map(date => {
    const entry: Record<string, any> = { insert_date: date };
    allBodyParams.forEach(item => {
      if (item.insert_date.startsWith(date)) {
        entry[item.dict_body_params_name] = item.value;
      }
    });
    return entry;
  });

  // Losowe, ale stabilne kolory dla linii
  const getRandomColor = (param: string) => {
    const hash = param.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return `#${((hash * 16777215) % 16777215).toString(16).padStart(6, "0")}`;
  };

  return (
    <div style={{ height: "500px", width: "100%" }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={processedData} margin={{ top: 5, right: 30, left: 20, bottom: 50 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="insert_date" angle={-45} textAnchor="end" height={80} />
          <YAxis />
          <Tooltip />
          <Legend />
          {paramNames.map(param => (
            <Line 
            key={param} 
            type="monotone" 
            dataKey={param} 
            name={param} 
            stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`}
            strokeWidth={2}
            connectNulls={true} 
            dot={{ r: 6 }} // Mniejsze kropki dla lepszego wyglądu

            // stroke={getRandomColor(param)
          />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ShowDiagram;
