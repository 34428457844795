import React, { Component } from "react";
import { Tabs, Tab, TabList, TabPanel } from 'react-tabs';
import Service from '../../services/workbooks';
import 'react-tabs/style/react-tabs.css';
import { Workbook } from '../../types/types';
import Wrapper from './workbook/Wrapper';
import { withTranslation } from "react-i18next";
import Modal from "../../constants/Modal";
import DiagramTrainingFrequency from "./workbook/DiagramTrainingFrequency";
import DiagramRepetitionsSetsVolume from "./workbook/DiagramRepetitionsSetsVolume";



type Props = {
    t: any;
};
type State = {
    workbooks: Workbook[];
    newWorkbookName: string;
    activeTabIndex: number; // Dodajemy stan do przechowywania indeksu aktywnej zakładki
    showModal: boolean; // Dodany nowy stan do kontrolowania modala
};

class Training extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            workbooks: [],
            newWorkbookName: '',
            activeTabIndex: 0, // Domyślnie ustawiamy pierwszą zakładkę jako aktywną
            showModal: false, // Domyślnie modal jest zamknięty
        };
    }

    componentDidMount() {
        this.getWorkbooks();
        // Sprawdzamy, czy istnieje informacja o ostatniej aktywnej zakładce w localStorage
        const lastActiveTabIndex = localStorage.getItem('lastActiveTabIndex');
        if (lastActiveTabIndex !== null) {
            this.setState({ activeTabIndex: parseInt(lastActiveTabIndex) });
        }
    }

    getWorkbooks() {
        Service.getWorkbooks()
            .then(response => {
                const workbooks: Workbook[] = response.data;
                this.setState({ workbooks });
            })
            .catch(error => console.error('Error fetching workbooks:', error));
    }

    handleNewWorkbookNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ newWorkbookName: event.target.value });
    };

    handleAddWorkbook = () => {
        const { newWorkbookName } = this.state;
        if (newWorkbookName.trim() !== '') {
            Service.postNewWorkbook(newWorkbookName)
                .then(() => {
                    this.setState({ newWorkbookName: '' });
                })
                .then(() => {
                    this.getWorkbooks();
                });
        }
        this.setState((prevState) => ({ showModal: !prevState.showModal }));
    };

    handleTabSelect = (index: number) => {
        this.setState({ activeTabIndex: index });
        localStorage.setItem('lastActiveTabIndex', index.toString());
    };

    toggleModal = () => {
        this.setState((prevState) => ({ showModal: !prevState.showModal }));
    };

    render() {
        const { workbooks, newWorkbookName, activeTabIndex, showModal  } = this.state;
        const { t } = this.props;

        return (
            <div className="card-container">
                <div>
                    <Tabs selectedIndex={activeTabIndex} onSelect={this.handleTabSelect}>
                        <TabList>
                            {workbooks.map((workbook) => (
                                <Tab key={workbook.id}>{workbook.name}</Tab>
                            ))}
                            {/* Dodanie stałego taba "+" */}
                            <Tab onClick={this.toggleModal}>+</Tab>
                        </TabList>

                        {workbooks.map((workbook) => (
                            <TabPanel key={workbook.id}>
                            <Tabs>
                              <TabList>
                                <Tab>{t("workbooks.exercises")}</Tab>
                                <Tab>{t("workbooks.statistics")}</Tab>
                              </TabList>
                          
                              <TabPanel>
                                <Wrapper workbook={workbook} />
                              </TabPanel>
                          
                              <TabPanel>
                                <DiagramTrainingFrequency workbookId={workbook.id} />
                                <DiagramRepetitionsSetsVolume workbookId={workbook.id} />
                              </TabPanel>
                            </Tabs>
                          </TabPanel>
                          
                        ))}
                    </Tabs>

                    
                </div>

                {/* Reużywalny Modal */}
                <Modal show={showModal} onClose={this.toggleModal} title={t("workbooks.add_new_workbook")}>
                    <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
                        <input
                            type="text"
                            value={newWorkbookName}
                            onChange={this.handleNewWorkbookNameChange}
                            placeholder={t("workbooks.enter_new_workbook_name")}
                        />
                        <button onClick={this.handleAddWorkbook}>{t("buttons.add")}</button>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default withTranslation("global")(Training)