import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import AuthService from "../../services/auth.service";
import IUser from "../../types/user.type";
import GetInvitations from "./invitations/GetInvitations";
import { withTranslation } from "react-i18next";
import { LanguageMenu } from "../language/LanguageMenu";
import LanguageService from "../../services/languages";
import DeleteAccount from "./crud/DeleteAccount";
import Modal from "../../constants/Modal";
import UpdatePassword from "./crud/UpdatePassword";
import i18n from "../../translations/i18n";

import editIcon from "../../assets/icons/edit.svg";

type Props = {
  t: any;
  i18n: any;
};

type State = {
  redirect: string | null,
  userReady: boolean,
  currentUser: IUser & { accessToken: string },
  languageReady: boolean,
  showPasswordModal: boolean,
  showLanguageModal: boolean
};

class Profile extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      redirect: null,
      userReady: false,
      currentUser: { accessToken: "" },
      languageReady: false,
      showPasswordModal: false,
      showLanguageModal: false
    };
  }

  async componentDidMount() {
    const currentUser = AuthService.getCurrentUser();

    if (!currentUser) {
      this.setState({ redirect: "/home" });
      return;
    }

    this.setState({ currentUser: currentUser, userReady: true });

    try {
      const res = await LanguageService.getLanguage();
      const langCode = res.data.languageCode;
      await this.props.i18n.changeLanguage(langCode);
      this.setState({ languageReady: true });
    } catch (err) {
      console.error('Error fetching user language:', err);
    }
  }

  handleOpenPasswordModal = () => {
    this.setState({ showPasswordModal: true });
  };

  handleClosePasswordModal = () => {
    this.setState({ showPasswordModal: false });
  };

  handleOpenLanguageModal = () => {
    this.setState({ showLanguageModal: true });
  };

  handleCloseLanguageModal = () => {
    this.setState({ showLanguageModal: false });
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to={this.state.redirect} />;
    }

    const { t } = this.props;
    const { currentUser, userReady, languageReady, showPasswordModal, showLanguageModal } = this.state;

    if (!userReady || !languageReady) {
      return <div><span className="loader" /></div>;
    }

    return (
      <div className='card-container'>
        <div className='center'>
          <header>
            <strong>{t("profile.profile_name")}</strong> <strong>{currentUser.username}</strong>
          </header>
          <p>
            <strong>{t("profile.email")}</strong> {currentUser.email}
          </p>
          <p>
            <strong>{t("profile.language")} {i18n.language}</strong>
            <img
              src="/images/navigation/edit.svg"
              alt="Edit"
              style={{ cursor: "pointer", marginLeft: "10px", width: "16px", height: "16px" }}
              onClick={this.handleOpenLanguageModal}
            />
          </p>

          <GetInvitations />
          <button onClick={this.handleOpenPasswordModal}>{t("buttons.update_password")}</button>
          <DeleteAccount />


          <Modal show={showPasswordModal} onClose={this.handleClosePasswordModal} title="Update Password">
            <UpdatePassword />
          </Modal>

          <Modal show={showLanguageModal} onClose={this.handleCloseLanguageModal} title={t("navigation.select_language")}>
            <LanguageMenu />
          </Modal>
        </div>
      </div>
    );
  }
}

export default withTranslation("global")(Profile);
